import { redirect } from "react-router-dom";

import scraperApi, { ApiError, ApiErrorResponse } from "api";

import IRouterActionError from "./IRouterActionError";
import OkResponse from "./OkResponse";

import { formDataToStructuredObject } from "utils/formDataUtils";


export async function emailLoginAction({ request }: { request: Request }): Promise<Response | IRouterActionError | null> {
  const formDataObj = formDataToStructuredObject(await request.formData());

  try {
    await scraperApi.auth.login({
      email: formDataObj.email,
      password: formDataObj.password,
      token: formDataObj["token-type"] === "reCaptchaV2" ? formDataObj["g-recaptcha-response"] : formDataObj["turnstile-token"],
      tokenType: formDataObj["token-type"]
    });

    // TODO might need better response handling here
    return null;
  } catch (error) {
    return {
      error: {
        message: (error as Error).message
      }
    };
  }
}

export async function emailSignupAction({ request }: { request: Request }): Promise<Response | IRouterActionError> {
  const formDataObj = formDataToStructuredObject(await request.formData());

  try {
    await scraperApi.auth.signup({
      email: formDataObj.email,
      password: formDataObj.password,
      token: formDataObj["token-type"] === "reCaptchaV2" ? formDataObj["g-recaptcha-response"] : formDataObj["turnstile-token"],
      tokenType: formDataObj["token-type"]
    });

    return redirect("/send-activation-email");
  } catch (error) {
    return {
      error: {
        message: (error as Error).message
      }
    };
  }
}


interface SocialSignupFormData {
  "google-token"?: string;
  redirect?: string;
}

const socialSignupActions: { [ index: string ]: (formDataObj: SocialSignupFormData) => any } = {
  'github': async (formDataObj): Promise<Response | IRouterActionError> => {
    try {
      const newState = await scraperApi.auth.github.state();
      const githubParams = new URLSearchParams({
        scope: "user:email",
        client_id: process.env.REACT_APP_GITHUB_OAUTH_CLIENT_ID,
        state: newState,
        redirect_uri: `${ process.env.REACT_APP_API_URL }/oauth/github/sign-up?${ new URLSearchParams({ redirect: formDataObj.redirect || "" }).toString() }`,
      });
      return redirect(`https://github.com/login/oauth/authorize?${ githubParams.toString() }`);
    } catch (error) {
      return {
        error: {
          message: (error as Error).message
        }
      } as IRouterActionError;
    }
  },
  'google': async (formDataObj): Promise<Response | IRouterActionError> => {
    try {
      await scraperApi.auth.google.signup(formDataObj["google-token"] || "");
      return redirect(formDataObj.redirect || "/");
    } catch (error) {
      const err = error as Error & { error_code?: string };
      if (err.error_code === "err_google_auth_failed") {
        if (err.message?.startsWith("Token used too late")) {
          return {
            error: {
              taggedMessage: {
                message: "Your authentication token has expired. Please go back to the [login page|login_page] and try again.",
                linkStyle: "transition hover:brightness-75"
              }
            }
          };
        } else {
          return {
            error: {
              taggedMessage: {
                message: "There was an error signing you up. Please go to the [signup page|signup_page] and try again.",
                linkStyle: "transition hover:brightness-75"
              }
            }
          }
        }
      }

      return {
        error: {
          message: (error as Error).message
        }
      };
    }
  }
};

export async function socialSignupAction({ request }: { request: Request }): Promise<Response | IRouterActionError> {
  const formDataObj = formDataToStructuredObject(await request.formData());

  const socialSignupAction = socialSignupActions[formDataObj.provider];

  if (!socialSignupAction) {
    throw new Error(`Social signup type '${ formDataObj.provider } is not supported.`);
  }

  return await socialSignupAction(formDataObj);
}

export async function forgotPasswordAction({ request }: { request: Request }): Promise<string | IRouterActionError> {
  const formDataObj = formDataToStructuredObject(await request.formData());

  try {
    await scraperApi.auth.forgotPassword(formDataObj.email);
    // in case the email has been sent successfully, return the email from this action to update the page
    return formDataObj.email;
  } catch (error) {
    return {
      error: {
        message: (error as Error).message
      }
    };
  }
}

export async function resetPasswordAction({ request }: { request: Request }): Promise<Response | IRouterActionError> {
  const formDataObj = formDataToStructuredObject(await request.formData());

  try {
    await scraperApi.auth.resetPassword(formDataObj.password, formDataObj.token);
    return new OkResponse();
  } catch (error) {
    const apiError = error as ApiError<ApiErrorResponse>;
    if (apiError.error_code === "token_expired") {
      return {
        error: {
          taggedMessage: {
            message: "Your password reset link has expired. Please [go back here|forgot_password] and request a new link."
          }
        }
      };
    }

    return {
      error: {
        message: (error as Error).message
      }
    };
  }
}
