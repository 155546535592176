import React from "react";
import ReactDOM from "react-dom/client";
import { HeadProvider } from "react-head";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "@fontsource/work-sans/latin.css";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useSearchParams
} from "react-router-dom";

import "./index.css";

import { BlockedLayout } from "components/BlockedLayout";
import { FeatureSwitchLayout, useFeatureSwitch } from "components/FeatureSwitch";
import * as Modal from "components/Modal";
import ProtectedBillingLayout from "components/ProtectedBillingLayout";
import ProtectedLayout from "components/ProtectedLayout";
import Sidebar from "components/Sidebar";
import { GlobalTooltip } from "components/Tooltip";
import TopBanner from "components/TopBanner";

import useNewOnboardingFeatureSwitch from "components/onboarding/useNewOnboardingFeatureSwitch";

import AuthLayout from "layouts/AuthLayout";
import BannedFromFreeLayout from "layouts/BannedFromFreeLayout";
import BeforeLoginLayout from "layouts/BeforeLoginLayout";
import BorderLayout from "layouts/BorderLayout";
import DynamicServicesProviderLayout from "layouts/DynamicServicesProviderLayout";
import OnboardingLayout, { OnboardingQuestionsLayout } from "layouts/OnboardingLayout";
import ThirdPartyServicesLayout from "layouts/ThirdPartyServicesLayout";

import ApiSettingsProvider from "providers/ApiSettingsProvider";
import ChargebeeProvider from "providers/ChargebeeProvider";
import UserProvider from "providers/UserProvider";
import OnboardTooltipProvider from "providers/OnboardingTooltipProvider";
import OfferSocialSignup from "routes/offer-social-signup";
import ChargebeePortalProvider from "providers/ChargebeePortalProvider";
import IssuesProvider from "providers/IssuesProvider";
import { HostedScrapingProjectsProvider } from "providers/HostedScrapingProvider";
import ApiCallsProvider from "providers/ApiCallsProvider";
import NewDashboardDesignProvider, { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";

import AuthError from "routes/auth-error";
import ForgotPassword from "routes/forgot-password";
import Login from "routes/login";
import MagicLink from "routes/magic-link";
import { SendActivationEmailNew, SendActivationEmailOld } from "routes/send-activation-email";
import Signup from "routes/signup";
import ResetPassword from "routes/reset-password";

import BeautifulProjectsView from "routes/dashboard/BeautifulProjectsView";
import Billing from "routes/dashboard/Billing";
import DashboardHome from "pages/dashboard/Dashboard";
import NewApiRequest from "routes/dashboard/NewApiRequest";
import NewProject from "routes/dashboard/NewProject";
import ProjectDetails from "routes/dashboard/ProjectDetails";
import ProjectEditPage from "routes/dashboard/ProjectEditPage";

import * as Authentication from "routes/dataroutes/Authentication";
import * as BillingData from "routes/dataroutes/BillingData";
import * as DashboardData from "routes/dataroutes/DashboardData";
import * as UserData from "routes/dataroutes/UserData";

import * as UserShortcuts from "routes/shortcuts/UserShortcuts";

import { getCookie, setCookie } from "utils/cookieUtils";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare global {
  interface Window {
    Chargebee: any;
    gtag: any;
    ZohoGCSettings: {
      isOpenDefault: boolean;
      needClose: boolean;
      removeMinimize: boolean;
    }
  }

  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_GOOGLE_LOGIN_CLIENT_ID: string;
      REACT_APP_DOMAIN: string;
      REACT_APP_SUBDOMAIN: string;
      REACT_APP_PORT: string;
      REACT_APP_APP_ENV: "development" | "staging" | "production";
      REACT_APP_APP_CHARGEBEE_SITE_NAME: string;
      REACT_APP_APP_CHARGEBEE_PUBLISHABLE_KEY: string;
      REACT_APP_GITHUB_OAUTH_CLIENT_ID: string;
      REACT_APP_API_URL: string;
      REACT_APP_RECAPTCHA_V2_SITE_KEY: string;
      REACT_APP_USE_NEW_ONBOARDING: string;
      REACT_APP_NEW_ONBOARDING_PERCENTAGE: string;
      REACT_APP_NEW_SALES_BANNER_USERS: string;
      REACT_APP_PAYPAL_CLIENT_ID: string;
    }
  }
}

function NavigateToAccountActivationPage() {
  const [ searchParams ] = useSearchParams();

  return <Navigate to={ `/activate-account?${searchParams.toString()}` } replace />;
}

function NewOnboardingFeatureSwitch({ oldOnboardingElement, newOnboardingElement }: { oldOnboardingElement: JSX.Element, newOnboardingElement: JSX.Element }) {
  const useNewOnboarding = useNewOnboardingFeatureSwitch();

  return useNewOnboarding ? newOnboardingElement : oldOnboardingElement;
}


const Root = () => {
  const location = useLocation();
  const state = location?.state as { backgroundLocation?: Location };
  const checkBillingAddressInUpgradeDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
  const checkPaymentSourcesInUpgradeDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");
  const useNewSubscriptionDialogs = checkBillingAddressInUpgradeDialog || checkPaymentSourcesInUpgradeDialog;
  const { newDashboardDesignSetting } = useNewDashboardDesign();

  return (
    <>
      <Routes location={ state?.backgroundLocation || location }>
        <Route
          element={ <ProtectedLayout/> }
        >
          <Route
            path="/"
            element={
              <BorderLayout
                top={ <TopBanner/> }
                left={ <Sidebar/> }
                wrap="md"
                scrollMainWhenWrapped
                className={ (newDashboardDesignSetting === "yes") && (location?.pathname === "/" || location?.state?.backgroundLocation?.pathname === "/") ? "dark" : "" }
              />
            }
          >
            <Route path="/" element={ <DashboardHome/> }/>
            <Route path="/billing" element={ <Billing/> }>
              <Route
                path="plans"
              >
                <Route element={ <ProtectedBillingLayout /> }>
                  <Route
                    path="upgrade/:planId"
                    element={ <Modal.UpgradeModal /> }
                    errorElement={ <Modal.UpgradeErrorModal/> }
                    // TODO should set the action here, once the modal has been moved to the data router part (out of <Root>)
                    // action={ upgradeSubscriptionAction }
                  />
                </Route>
              </Route>
            </Route>

            <Route
              element={ <BlockedLayout/> }
            >
              <Route
                path="/projects"
              >
                <Route index={ true } element={ <BeautifulProjectsView/> }/>
                <Route path="new" element={ <NewProject/> }/>
                <Route path=":projectId" element={ <ProjectDetails/> }/>
                <Route path=":projectId/edit" element={ <ProjectEditPage/> }/>
              </Route>
            </Route>


            <Route path="/" element={
              <BlockedLayout>
                <FeatureSwitchLayout featureSwitch="REACT_APP_API_PLAYGROUND_USERS"/>
              </BlockedLayout>
            }>
              <Route
                path="/apiplayground"
              >
                <Route index={ true } element={ <NewApiRequest/> }/>
              </Route>
            </Route>


            <Route path="/projectslist" element={ <Navigate to="/projects" replace/> }/>
            <Route
              path="/change-password"
              element={ <Modal.ChangePasswordModal/> }
            />
            <Route
              path="/contact-support"
              element={ <Navigate to="/" state={ { openSupportWidget: true } }/> }
            />
            <Route
              path="/contact-sales"
              element={<Navigate to="/book-sales-call" state={ { backgroundLocation: "/" } } />}
            />

            <Route path="/" element={ <BlockedLayout/> }>
              <Route
                path="/book-sales-call"
                element={ <Navigate to="/book-sales-call" state={ { backgroundLocation: "/" } }/> }
              />

              <Route
                path="/cancel-subscription"
                element={ <Modal.CancelSubscriptionModal/> }
              />
              <Route
                path="/cancel-enterprise"
                element={ <Modal.CancelEnterpriseModal/> }
              />

              <Route path="/" element={ <ProtectedBillingLayout/> }>
                <Route
                  path="/cancel-subscription-change"
                  element={ <Modal.CancelSubscriptionChangeModal/> }
                />
                <Route
                  path="/confirm-renewal/:planId"
                  element={ <Modal.ConfirmRenewalModal/> }
                />
                {/* TODO /auto-renewal/clear does not necessarily need to check the billing info */ }
                <Route
                  path="/auto-renewal/clear"
                  element={ <Modal.ClearAutoRenewalModal/> }
                />
                {/* TODO /auto-renewal/:ceiling does not necessarily need to check the billing info */ }
                <Route
                  path="/auto-renewal/:ceiling"
                  element={ <Modal.AutoRenewalModal/> }
                />
                <Route
                  path="/downgrade-annual-subscription/:planId"
                  element={ <Modal.DowngradeAnnualModal/> }
                />
                <Route
                  path="/downgrade-subscription/:planId"
                  element={ <Modal.DowngradeModal/> }
                />
                <Route
                  path="/coupons"
                  element={ <Modal.CouponsModal/> }
                />
              </Route>
            </Route>

            <Route element={ <BannedFromFreeLayout/> }>
              <Route
                element={ <ProtectedBillingLayout/> }
              >
                <Route
                  path="/pay-now"
                  element={ <Modal.PayNowModal/> }
                />
              </Route>
              { !useNewSubscriptionDialogs && (
                <Route
                  element={ <ProtectedBillingLayout/> }
                >
                  <Route
                    path="/upgrade-annual-subscription/:planId"
                    element={ <Modal.UpgradeAnnualModal/> }
                  />
                  <Route
                    path="/upgrade-subscription/:planId"
                    element={ <Modal.UpgradeModal/> }
                  />
                </Route>
              ) }
            </Route>
          </Route>

          <Route path="/projectslist" element={ <Navigate to="/projects" replace/> }/>
          <Route
            path="/change-password"
            element={ <Modal.ChangePasswordModal/> }
          />
          <Route
            path="/contact-support"
            element={ <Navigate to="/" state={ { openSupportWidget: true } }/> }
          />

          <Route path="/" element={ <BlockedLayout/> }>
            <Route
              path="/cancel-subscription"
              element={ <Modal.CancelSubscriptionModal/> }
            />
            <Route
              path="/cancel-enterprise"
              element={ <Modal.CancelEnterpriseModal/> }
            />
            <Route
              path="/datapipeline/project/:projectId/error-report/:publicId"
              element={ <Modal.HostedScraperErrorReportModal/> }
            />

            <Route path="/" element={ <ProtectedBillingLayout/> }>
              <Route
                path="/cancel-subscription-change"
                element={ <Modal.CancelSubscriptionChangeModal/> }
              />
              <Route
                path="/confirm-renewal/:planId"
                element={ <Modal.ConfirmRenewalModal/> }
              />
              {/* TODO /auto-renewal/clear does not necessarily need to check the billing info */ }
              <Route
                path="/auto-renewal/clear"
                element={ <Modal.ClearAutoRenewalModal/> }
              />
              {/* TODO /auto-renewal/:ceiling does not necessarily need to check the billing info */ }
              <Route
                path="/auto-renewal/:ceiling"
                element={ <Modal.AutoRenewalModal/> }
              />
              <Route
                path="/downgrade-annual-subscription/:planId"
                element={ <Modal.DowngradeAnnualModal/> }
              />
              <Route
                path="/downgrade-subscription/:planId"
                element={ <Modal.DowngradeModal/> }
              />
              <Route
                path="/coupons"
                element={ <Modal.CouponsModal/> }
              />
            </Route>
          </Route>
        </Route>

        {/*
          The API and potentially other links pointing to these urls.
          We need to redirect them to the appropriate route.
        */ }
        <Route
          path="/sendActivationEmail"
          element={ <Navigate to="/send-activation-email" replace/> }
        />
        <Route
          path="/activateAccount"
          element={ <NavigateToAccountActivationPage/> }
        />
        <Route path="/dashboard" element={ <Navigate to="/" replace/> }/>

      </Routes>

      { state?.backgroundLocation && (
        <Routes>
          <Route
            path="/datapipeline/project/:projectId/error-report/:publicId"
            element={ <Modal.HostedScraperErrorReportModal/> }
          />
          <Route
            path="/"
            element={
              <ProtectedLayout>
                <Outlet/>
              </ProtectedLayout>
            }
          >
            <Route
              path="/change-password"
              element={ <Modal.ChangePasswordModal/> }
            />
            <Route
              path="/contact-support"
              element={ <Navigate to="/" state={ { openSupportWidget: true } }/> }
            />

            <Route path="/" element={ <BlockedLayout/> }>
              <Route
                path="/cancel-subscription"
                element={ <Modal.CancelSubscriptionModal/> }
              />
              <Route
                path="/cancel-enterprise"
                element={ <Modal.CancelEnterpriseModal/> }
              />
              <Route
                path="/keep-subscription"
                element={ <Modal.KeepSubscriptionModal /> }
              />
              <Route
                path="/delete-account"
                element={ <Modal.DeleteAccountModal/> }
              />
              <Route
                path="/api-keys"
                element={ <Modal.ApiKeyManagement/> }
              />
              <Route
                path="/api-keys/confirm"
                element={ <Modal.ConfirmApiKeyChangeModal/> }
              />
              <Route
                path="/book-sales-call"
                element={ <Modal.BookSalesCallModal/> }
              />

              <Route path="/" element={ <ProtectedBillingLayout/> }>
                <Route
                  path="/cancel-subscription-change"
                  element={ <Modal.CancelSubscriptionChangeModal/> }
                />
                <Route
                  path="/confirm-renewal/:planId"
                  element={ <Modal.ConfirmRenewalModal/> }
                />
                {/* TODO /auto-renewal/clear does not necessarily need to check the billing info */ }
                <Route
                  path="/auto-renewal/clear"
                  element={ <Modal.ClearAutoRenewalModal/> }
                />
                {/* TODO /auto-renewal/:ceiling does not necessarily need to check the billing info */ }
                <Route
                  path="/auto-renewal/:ceiling"
                  element={ <Modal.AutoRenewalModal/> }
                />
                <Route
                  path="/downgrade-annual-subscription/:planId"
                  element={ <Modal.DowngradeAnnualModal/> }
                />
                <Route
                  path="/downgrade-subscription/:planId"
                  element={ <Modal.DowngradeModal/> }
                />
                <Route
                  path="/coupons"
                  element={ <Modal.CouponsModal/> }
                />
              </Route>
            </Route>

            <Route element={ <BannedFromFreeLayout/> }>
              <Route
                element={ <ProtectedBillingLayout/> }
              >
                <Route
                  path="/pay-now"
                  element={ <Modal.PayNowModal/> }
                />
              </Route>

              <Route
                element={ useNewSubscriptionDialogs ? <Outlet/> : <ProtectedBillingLayout/> }
              >
                <Route
                  path="/upgrade-annual-subscription/:planId"
                  element={ <Modal.UpgradeAnnualModal/> }
                />
                <Route
                  path="/upgrade-subscription/:planId"
                  element={ <Modal.UpgradeModal/> }
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      ) }
    </>
  );
};

function ProvidersLayout() {
  return (
    <NewDashboardDesignProvider>
      <ApiCallsProvider>
        <ApiSettingsProvider>
          <UserProvider>
            <IssuesProvider>
              <ChargebeeProvider
                publishableKey={ process.env.REACT_APP_APP_CHARGEBEE_PUBLISHABLE_KEY }
                site={ process.env.REACT_APP_APP_CHARGEBEE_SITE_NAME }
                enableGTMTracking={ true }
                enableGATracking={ true }
              >
                <ChargebeePortalProvider>
                  <OnboardTooltipProvider>
                    <HostedScrapingProjectsProvider>
                      <Outlet/>
                      <GlobalTooltip/>
                    </HostedScrapingProjectsProvider>
                  </OnboardTooltipProvider>
                </ChargebeePortalProvider>
              </ChargebeeProvider>
            </IssuesProvider>
          </UserProvider>
        </ApiSettingsProvider>
      </ApiCallsProvider>
    </NewDashboardDesignProvider>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      id={ UserData.id }
      lazy={ () => import("routes/dataroutes/UserData") }
    >
      <Route
        element={ <>
          <ToastContainer
            position="bottom-right"
            closeButton={ false }
            closeOnClick={ false }
            draggable={ false }
            className="min-w-min" // without this the width of the toaster didn't match the width of its contents :/
          />
          <Outlet/>
        </> }
      >
        <Route
          element={ <DynamicServicesProviderLayout/> }
        >
          <Route
            element={ <ThirdPartyServicesLayout/> }
          >
            <Route
              element={ <BeforeLoginLayout/> }
            >
              <Route
                path="/auth-error"
                element={ <AuthError/> }
              />

              <Route
                element={ <UserShortcuts.ToDashboardIfAlreadyAuthenticated/> }
              >
                <Route
                  path="/login"
                  element={ <Login/> }
                  action={ Authentication.emailLoginAction }
                />
                <Route
                  path="/signup"
                  element={ <Signup/> }
                  action={ Authentication.emailSignupAction }
                />
                <Route
                  path="/offer-social-signup"
                  element={ <OfferSocialSignup/> }
                  action={ Authentication.socialSignupAction }
                />
                <Route
                  path="/forgot-password"
                  element={ <ForgotPassword/> }
                  action={ Authentication.forgotPasswordAction }
                />
                <Route
                  path="/reset"
                  element={ <ResetPassword/> }
                  action={ Authentication.resetPasswordAction }
                />
              </Route> {/* UserShortcuts.ToDashboardIfAlreadyAuthenticated */ }

            </Route> {/* BeforeLoginLayout */ }

            <Route
              element={ <UserShortcuts.ToLoginIfNotAuthenticated/> /* part of old ProtectedLayout */ }
            >
              <Route
                element={ <NewOnboardingFeatureSwitch oldOnboardingElement={ <AuthLayout/> }
                                                      newOnboardingElement={ <OnboardingLayout/> }/> }
              >
                <Route
                  path="/send-activation-email"
                  element={ <NewOnboardingFeatureSwitch oldOnboardingElement={ <SendActivationEmailOld/> }
                                                        newOnboardingElement={ <SendActivationEmailNew/> }/> }
                />
              </Route> {/* OnboardingLayout or AuthLayout */ }

              <Route
                path="/magic-link"
                element={ <MagicLink/> }
              />

            </Route> {/* UserShortcuts.ToLoginIfNotAuthenticated */ }

            <Route
              element={ <NewOnboardingFeatureSwitch oldOnboardingElement={ <AuthLayout/> }
                                                    newOnboardingElement={ <OnboardingLayout/> }/> }
            >
              <Route
                path="/activate-account"
                lazy={ () => import("routes/activate-account") }
              />
            </Route>

            <Route
              element={ <UserShortcuts.ToActivationPageIfDeactivated/> }
            >
              <Route
                element={ <OnboardingQuestionsLayout/> }
              >
                <Route
                  path="/welcome/:step"
                  lazy={() => import("routes/dataroutes/Onboarding") }
                />
              </Route> {/* OnboardingQuestionsLayout */ }

            </Route> {/* UserShortcuts.ToActivationPageIfDeactivated */ }

            <Route
              path="/billing-data"
            >
              <Route
                path="coupons"
              >
                <Route
                  path="check"
                  // TODO this should be a GET submission with an action
                  loader={ BillingData.couponsLoader }
                />
              </Route>

              <Route
                path="invoices"
              >
                <Route
                  path="not-paid"
                  loader={ BillingData.notPaidInvoicesLoader }
                />
              </Route>

              <Route
                path="billing-address"
                loader={ BillingData.billingAddressLoader }
              />
              <Route
                path="payment-sources"
                loader={ BillingData.paymentSourcesLoader }
              />

              <Route
                path="subscription"
              >
                <Route
                  path="details"
                  loader={ BillingData.subscriptionDetailsLoader }
                />
                <Route
                  path="upgrade/:planId"
                  action={ BillingData.upgradeSubscriptionAction }
                />
                <Route
                  path="cancel"
                  action={ BillingData.cancelSubscriptionAction }
                />
                <Route
                  path="cancellation-survey"
                  action={ BillingData.postCancellationSurveyAnswersAction }
                />
                <Route
                  path="keep"
                  action={ BillingData.removeScheduledChangesAction }
                />
              </Route>
            </Route>

            <Route
              path="/dashboard-data"
            >
              <Route
                path="changelog"
                loader={ DashboardData.changelogLoader }
              />

              <Route
                path="usage-history"
                loader={ DashboardData.usageHistoryLoader }
              />
              <Route
                path="per-domain-report"
                loader={ DashboardData.downloadPerDomainReportLoader }
              />
            </Route>

            <Route element={ <ProvidersLayout/> }>
              <Route path="*" Component={ Root }/>
            </Route>

          </Route> {/* ThirdPartyServicesLayout */ }
        </Route> {/* DynamicServicesProviderLayout */ }
      </Route> {/* ToastContainer */ }
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <HeadProvider>
      <RouterProvider router={ router }/>
    </HeadProvider>
  </React.StrictMode>
);


if (!getCookie("saFirstPage")) {
  setCookie(
    "saFirstPage",
    JSON.stringify({
      url: window.location.href,
      visited: new Date()
    })
  );

  setCookie(
    "saReferrer",
    document.referrer
  );
}
