export const AdditionalOptionsNumberBox = ({ value, placeholder, callback }: { value: string | number | undefined, placeholder: string, callback: (value: string | undefined) => void }) => {
  return (<div className="w-full">
    <input className="w-full border border-lightest shadow placeholder-gray-200 dark:placeholder-neutral-200 text-sm p-2" placeholder={placeholder} value={value} type="number" min="0"  onChange={(ev) => {
      const newValue = ev.target.value;
      if (newValue === "") {
        callback(undefined);
      } else {
        callback(newValue);
       } 
      }}

      onInput={ (ev: any) => {
        if (!ev.target.validity.valid) {
          ev.preventDefault();
        }
      }}
  
     />
  </div>);
};
