import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useActionData, useFetcher, useNavigation, useParams } from "react-router-dom";
import { Disclosure, Tab } from "@headlessui/react";
import { RiArrowDropDownLine, RiBankCard2Line, RiPaypalLine } from "@remixicon/react";
import _ from "lodash";
import {
  PayPalButtons,
  PayPalScriptProvider
} from "@paypal/react-paypal-js";


import { cx, fmtCurrency } from "utils";
import { applyCoupon, isAnnualPlan } from "utils/planUtils";
import { checkValidity } from "utils/validationUtils";

import Button from "components/Button";
import CardExpiryInput from "components/CreditCardInputs/CardExpiryInput";
import CardNumberInput from "components/CreditCardInputs/CardNumberInput";
import ComboBoxWithValidation from "components/ComboBoxWithValidation";
import CVVInput from "components/CreditCardInputs/CVVInput";
import { useFeatureSwitch } from "components/FeatureSwitch";
import InputFieldComponents from "components/InputFieldComponents/InputFieldComponents";
import InputFieldWithValidation from "components/InputFieldWithValidation";
import PriceTag from "components/PriceTag";
import Spinner from "components/Spinner";
import SubmitButton from "components/SubmitButton";
import Toaster from "components/Toaster";

import { useUserProvider } from "providers/UserProvider";

import IRouterActionError from "routes/dataroutes/IRouterActionError";
import { useUser } from "routes/dataroutes/UserData";
import { Fetchers } from "routes/dataroutes/Fetchers";

import Iso3166CountryCodes from "misc/Iso3166CountryCodes";

import Modal, { useCloseModal } from "./index";

import scraperApi from "api";


interface IInputProps {
  label: string;
}

function PlanContent() {
  const { planId: targetPlanId } = useParams();
  const couponsFetcher = useFetcher(Fetchers.COUPON_FETCHER);
  const { plans } = useUserProvider();
  const targetPlan = _.clone(plans?.find(plan => plan.id === targetPlanId));

  if (!targetPlan) {
    return (
      <div className="flex flex-row items-center p-3 bg-lightestGray-75 dark:bg-neutral-50">
        <div className="flex flex-row items-start gap-x-2">
          <span className="text-gray dark:text-neutral-600 italic">Loading plan data...</span>
          <Spinner className="w-4 h-4 animate-spin text-gray dark:text-neutral-600" />
        </div>
      </div>
    );
  }

  applyCoupon(targetPlan, couponsFetcher.data);

  const actualPrice = (targetPlan.discount_price === undefined ? targetPlan.price : targetPlan.discount_price) || 0;

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className="flex flex-row justify-between items-center p-3 bg-lightestGray-75 dark:bg-neutral-50">
        <div className="flex flex-col items-start">
          <div className="font-semibold text-lg">{ targetPlan?.name }</div>
          <div className="text-sm">{ isAnnualPlan(targetPlanId) ? "Annual" : "Monthly" } subscription</div>
        </div>
        <div className="flex flex-col items-end">
          <PriceTag
            size="small"
            price={ actualPrice }
            originalPrice={ targetPlan.price }
            period={ targetPlan.period || 1 }
            periodUnit={ targetPlan.period_unit || "month" }
            isAnnual={ isAnnualPlan(targetPlanId) || false }
          />
        </div>
      </div>

      <div className="text-justify">
        By upgrading your subscription, we will reset your credit counters, invoice { fmtCurrency(actualPrice / 100) } now and set your billing date
        to today.
      </div>
    </div>
  );
}

function CouponContent() {
  const { planId: targetPlanId } = useParams();

  const couponFetcher = useFetcher(Fetchers.COUPON_FETCHER);


  const debouncedCouponCheck = _.debounce(
    async (value: string | undefined, postCheckCallback?: () => void) => {
      couponFetcher.load(
        `/billing-data/coupons/check?coupon-code=${ value }&target-plan-id=${ targetPlanId }`
      );
    },
    500
  );

  return (
    <Disclosure>
      { ({ open }) => (
        <>
          <Disclosure.Button>
            <div className="flex flex-row text-brandPrimary dark:text-primary-600">
              Have a coupon?
              <RiArrowDropDownLine className={ cx(open && "-rotate-180", "transition-transform duration-300" ) }/>
            </div>
          </Disclosure.Button>

          <Disclosure.Panel static>
            <div className="flex flex-row items-end w-full">
              <div className={ cx(
                "grid grid-rows-[min-content_min-content] grid-cols-[auto_min-content] grid-flow-col w-full gap-x-3",
                !open && "invisible"
              ) }>
                <InputFieldComponents
                  label="Coupon code"
                  name="coupon.coupon_code"
                  type="text"
                  placeholder="Enter a coupon code"
                  onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                    debouncedCouponCheck.cancel();
                    debouncedCouponCheck(event.currentTarget.value);
                  } }
                  TrailingElement={
                    <>
                      {/* TODO need to figure out a way to properly clear the input content on clicking the X button */}
                      {/*{ couponData?.coupon_code && (*/}
                      {/*  <RiCloseLine*/}
                      {/*    className="text-lightGray dark:text-neutral-500 cursor-pointer"*/}
                      {/*    onClick={ (event: any) => {*/}
                      {/*      let z = 0;*/}
                      {/*    } }*/}
                      {/*  />*/}
                      {/*) }*/}
                      { couponFetcher.state === "idle" ? undefined : <Spinner className="w-4 h-4 animate-spin" /> }
                    </>
                  }
                  errorMessage={ couponFetcher.data?.error }
                  successMessage={ couponFetcher.data?.coupon_name }
                  value={ couponFetcher.data?.coupon_code }
                  isValidated={ couponFetcher.data?.coupon_code !== undefined }
                />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function ActionButtons() {
  const navigation = useNavigation();
  const closeModal = useCloseModal({ blockNavigationIf: navigation.state === "submitting", goBackInHistoryOnClose: true });
  const actionData = useActionData();

  return (
    <div className="flex flex-row justify-end items-center w-full gap-x-3 pt-4">
      <Button text="Cancel" className="button button-tertiary" onClick={ closeModal } />
      <SubmitButton text="Upgrade subscription" checkFormValidity icon={ { absolute: false } } disabled={ actionData === null } />
    </div>
  )
}

function AuthorizationMessage() {
  return (
    <div className="text-xs text-gray dark:text-neutral-600">
      I authorize SaaS.group LLC to save this payment method and automatically charge this payment method whenever a
      subscription is associated with it.
    </div>
  );
}

function RenderInputs({ riKey, layout, data, namePrefix, actionData }: { riKey: string | number, layout: any, data: any, namePrefix?: string, actionData?: string | IRouterActionError }) {
  // TODO should come from the useActionData hook, once the modal has been moved to the data router part
  // const errors = useActionData() as { [ index: string ]: string | undefined } | undefined;
  const errors = actionData as IRouterActionError;

  const [ mutableErrors, setMutableErrors ] = useState(errors?.error?.formInputErrors);

  useEffect(() => {
    setMutableErrors(errors?.error?.formInputErrors);
  }, [ errors ]);

  if (Array.isArray(layout)) {
    return (
      <div key={ riKey } className="flex flex-row gap-x-3">
        { layout.map((row: any, index: number) => {
          const newKey = riKey + "_" + index;
          return <RenderInputs key={ newKey } riKey={ newKey } layout={ row } data={ data } namePrefix={ namePrefix } actionData={ actionData } />;
        }) }
      </div>
    );
  } else {
    return (
      <div key={ riKey } className="flex flex-col gap-y-3">
        { Object.entries(layout).map(([ field, subLayout ]) => {
          const newKey = riKey + "_" + field;

          if (_.has(subLayout, "label")) {
            if (_.has(subLayout, "options")) {
              // combo box input
              const comboBoxInputProps = subLayout as IComboBoxInputProps<any>;
              return (
                <ComboBoxWithValidation
                  key={ newKey }
                  name={ _.join([ namePrefix, field ], ".") }
                  options={ comboBoxInputProps.options }
                  inputDisplayValue={ comboBoxInputProps.inputDisplayValue }
                  listValue={ comboBoxInputProps.listValue }
                  required={ data[field]?.validation?.required }
                  isValidated
                  label={ comboBoxInputProps.label }
                  value={ comboBoxInputProps.options.find(option => (comboBoxInputProps.storedValue ? comboBoxInputProps.storedValue(option) : option) === data[field]?.value) }
                />
              );
            } else {
              // simple text input
              const textInputProps = subLayout as IInputProps;
              return (
                <InputFieldWithValidation
                  key={ newKey }
                  type="text"
                  label={ textInputProps.label }
                  name={ _.join([ namePrefix, field ], ".") }
                  value={ data[field]?.value }
                  required={ data[field]?.validation?.required }
                  errorMessage={ mutableErrors ? mutableErrors[field] : undefined }
                  isValidated
                  onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                    event.currentTarget.setCustomValidity("");
                    setMutableErrors(_.omit(mutableErrors, field));
                  } }
                />
              );
            }
          }
          else if (typeof subLayout === "string") {
            return (
              <InputFieldWithValidation
                key={ newKey }
                type="text"
                label={ subLayout }
                name={ _.join([ namePrefix, field ], ".") }
                value={ data[field]?.value }
                required={ data[field]?.validation?.required }
                errorMessage={ mutableErrors ? mutableErrors[field] : undefined }
                isValidated
                onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                  event.currentTarget.setCustomValidity("");
                  setMutableErrors(_.omit(mutableErrors, field));
                }}
              />
            )
          }

          return <RenderInputs key={ newKey } riKey={ newKey } layout={ subLayout } data={ data } namePrefix={ namePrefix } actionData={ actionData } />;
        }) }
      </div>
    )
  }
}

interface IInputLayout {
  [ index: string ]: IInputProps | IComboBoxInputProps<any> | IInputLayout[];
}

interface IComboBoxInputProps<T> extends IInputProps {
  options: T[];
  inputDisplayValue?: (item: T) => string;
  listValue?: (item: T) => string;
  storedValue?: (item: T) => any;
}

function BillingAddressInputs({ billingAddress, actionData }: { billingAddress: any, actionData?: string | IRouterActionError }) {
  const layout: IInputLayout = {
    name: [ { first_name: { label: "First name" } }, { last_name: { label: "Last name" } } ],
    line1: { label: "Address" },
    cityAndZip: [ { city: { label: "City" } }, { zip: { label: "ZIP" } } ],
    stateAndCountry: [ { state: { label: "State" } }, {
      country: {
        label: "Country",
        options: Object.values(Iso3166CountryCodes),
        inputDisplayValue: isoCountry => isoCountry?.name,
        listValue: isoCountry => isoCountry?.name,
        storedValue: isoCountry => isoCountry?.countryCode
      }
    } ]
  };

  return (
    <RenderInputs riKey="" layout={ layout } data={ billingAddress } namePrefix="billing_address" actionData={ actionData } />
  );
}

function CreditCardComponents(
  {
    validate = true,
    errors
  }: {
    validate?: boolean;
    errors?: IRouterActionError;
  }
) {

  const [ mutableErrors, setMutableErrors ] = useState(errors?.error?.formInputErrors);

  useEffect(() => {
    // update mutable errors here in case errors have been changed in a parent component
    // without this, the internal state won't be updated automatically
    setMutableErrors(errors?.error?.formInputErrors);
  }, [ errors ]);


  return (
    <div className="flex flex-col gap-y-3">
      <CardNumberInput
        name="payment_source.number"
        isValidated
        validate={ validate }
        errorMessage={ mutableErrors ? mutableErrors["number"] : undefined }
        onChange={ (event: ChangeEvent<HTMLInputElement>) => {
          event.currentTarget.setCustomValidity("");
          setMutableErrors(errors => _.omit(errors, "number"));
        } }
      />
      <div className="flex flex-row gap-x-3">
        <CardExpiryInput
          name="payment_source.expiry"
          isValidated
          validate={ validate }
          errorMessage={ mutableErrors ? mutableErrors["expiry_year"] || mutableErrors["expiry_month"] : undefined }
          onChange={ (event: ChangeEvent<HTMLInputElement>) => {
            event.currentTarget.setCustomValidity("");
            setMutableErrors(errors => _.omit(errors, "expiry_year", "expiry_month"));
          } }
        />
        <CVVInput
          name="payment_source.cvv"
          isValidated
          validate={ validate }
        />
      </div>
    </div>
  );
}

function PayPalComponents(
  {
    validate = true,
    onApproveBillingAgreement,
  }: {
    validate?: boolean,
    onApproveBillingAgreement?: (token: string | undefined) => void,
  }
) {

  const [ billingAgreementToken, setBillingAgreementToken ] = useState<string | undefined>();

  useEffect(() => {
    onApproveBillingAgreement?.(billingAgreementToken);
  }, [ onApproveBillingAgreement, billingAgreementToken ]);


  return (
    <>
      { validate && ( /* little hack to make the form and the submit button respond to switching between the credit card and paypal tabs. SubmitButton's mutationobserver will kick in here */
        <input type="hidden" name="payment_source.billing_agreement_token" value={ billingAgreementToken || "" } />
      ) }
      <PayPalScriptProvider
        options={ {
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
          disableFunding: "card",
          vault: true,
          intent: "tokenize",
        } }
      >
        <PayPalButtons
          createBillingAgreement={ async () => {
            setBillingAgreementToken(undefined);
            const billingAgreementTokenResponse = await scraperApi.billing.payPal.createBillingAgreementToken();
            return billingAgreementTokenResponse.billingAgreementToken;
          } }
          onApprove={ async (data, actions) => {
            // only update the hidden input with the billing token here
            // later when the user clicks the submit button, we'll pass this token to the backend where we approve the pending billing agreement
            // and also add a paypal-based payment method (using the approved billing agreement id) in chargebee
            setBillingAgreementToken(data.billingToken ?? undefined);
          } }
        />
      </PayPalScriptProvider>
    </>
  );
}

function PaymentMethodInputs(
  {
    actionData,
    payPalContext,
  }: {
    actionData?: string | IRouterActionError,
    payPalContext?: { setHasPayPalErrors: (agreementError: boolean) => void },
  }
) {
  // TODO should come from the useActionData hook, once the modal has been moved to the data router part
  // const errors = useActionData() as Response | IRouterActionError;
  const errors = actionData;

  const showPayPal = Boolean(process.env.REACT_APP_PAYPAL_CLIENT_ID);

  const availableTabs = [ "card", "paypal" ];
  const [ selectedPaymentSource, setSelectedPaymentSource ] = useState(availableTabs[0]);
  const [ approvedBillingAgreementToken, setApprovedBillingAgreementToken ] = useState<string | undefined>();

  useEffect(() => {
    if (payPalContext) {
      payPalContext.setHasPayPalErrors(selectedPaymentSource === "paypal" && !approvedBillingAgreementToken);
    }

  }, [ selectedPaymentSource, payPalContext, approvedBillingAgreementToken ]);


  return (
    <Tab.Group onChange={ (index) => setSelectedPaymentSource(availableTabs[index]) }>
      <input type="hidden" name="payment_source.type" value={ selectedPaymentSource } />

      <Tab.List className="flex flex-row p-1 bg-lightestGray-75 dark:bg-neutral-50 rounded-lg *:text-sm *:font-medium *:text-gray *:dark:text-neutral-600">
        <Tab className="w-full ui-selected:bg-white ui-selected:text-brandPrimary dark:ui-selected:text-primary-600 ui-not-selected:hover:text-gray-800 dark:ui-not-selected:hover:text-neutral-800 transition-colors rounded-md focus:outline-none ui-selected:shadow-sm ui-selected:shadow-lightestGray-200 dark:ui-selected:shadow-neutral-200">
          <div className="flex flex-row w-full items-center justify-center gap-x-2 p-2.5">
            <RiBankCard2Line className="w-[18px] h-[18px]" />
            <span>Credit Card</span>
          </div>
        </Tab>
        { showPayPal && (
          <Tab className="w-full ui-selected:bg-white ui-selected:text-brandPrimary dark:ui-selected:text-primary-600 ui-not-selected:hover:text-gray-800 dark:ui-not-selected:hover:text-neutral-800 transition-colors rounded-md focus:outline-none  ui-selected:shadow-sm ui-selected:shadow-lightestGray-200 dark:ui-selected:shadow-neutral-200">
            <div className="flex flex-row w-full items-center justify-center gap-x-2 p-2.5">
              <RiPaypalLine className="w-[18px] h-[18px]" />
              <span>PayPal</span>
            </div>
          </Tab>
        )}
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel unmount={ false }>
          <CreditCardComponents errors={ errors as IRouterActionError } validate={ selectedPaymentSource === "card" } />
        </Tab.Panel>
        { showPayPal && (
          <Tab.Panel unmount={ false }>
            <PayPalComponents validate={ selectedPaymentSource === "paypal" } onApproveBillingAgreement={ setApprovedBillingAgreementToken } />
          </Tab.Panel>
        ) }
      </Tab.Panels>
    </Tab.Group>
  );
}

function BillingInfoForm(
  {
    hasValidBillingAddress,
    billingAddress,
    hasValidPaymentSource,
    paymentSources,
    actionData
  }: {
    hasValidBillingAddress?: boolean;
    billingAddress: any;
    hasValidPaymentSource?: boolean;
    paymentSources: any;
    actionData?: string | IRouterActionError;
  }
) {
  // TODO should call useActionData here after properly moving the whole modal to the data router part
  // const actionData = useActionData();

  const [ hasPayPalErrors, setHasPayPalErrors ] = useState(false);

  const disableSubmitButton =
    // action has been performed successfully but the dialog is not closed yet. disable the submit button to prevent form resubmission
    (actionData === "OK")
    // showing the paypal controls but the billing agreement has not been approved yet by the user
    || hasPayPalErrors;


  return (
    <div
      className="flex flex-col w-full h-full gap-y-6"
    >
      { hasValidPaymentSource === false && (
        <PaymentMethodInputs actionData={ actionData } payPalContext={ { setHasPayPalErrors } }/>
      ) }

      { hasValidBillingAddress === false && (
        <BillingAddressInputs billingAddress={ billingAddress } actionData={ actionData } />
      ) }

      <AuthorizationMessage />
      <div className="flex flex-row items-end h-full">
        <SubmitButton
          text="Upgrade subscription"
          className="self-end button button-primary"
          fullWidth
          checkFormValidity
          trackDOMChanges
          disabled={ disableSubmitButton }
        />
      </div>
    </div>
  );
}


export default function UpgradeSubscriptionModal() {

  const user = useUser();

  const billingAddressFetcher = useFetcher();
  const checkBillingAddress = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
  const paymentSourcesFetcher = useFetcher();
  const checkPaymentSources = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");

  useEffect(() => {
    if (checkBillingAddress) {
      if (billingAddressFetcher.state === "idle" && !billingAddressFetcher.data) {
        billingAddressFetcher.load("/billing-data/billing-address");
      }
    }
    if (checkPaymentSources) {
      if (paymentSourcesFetcher.state === "idle" && !paymentSourcesFetcher.data) {
        paymentSourcesFetcher.load("/billing-data/payment-sources");
      }
    }
  }, [ billingAddressFetcher, paymentSourcesFetcher, checkBillingAddress, checkPaymentSources ]);

  const hasValidPaymentSource = useMemo(() => {
    return !checkPaymentSources ||
      (!paymentSourcesFetcher.data ? undefined : paymentSourcesFetcher.data[0]?.payment_source?.status === "valid");
  }, [ paymentSourcesFetcher.data, checkPaymentSources ]);

  const hasValidBillingAddress = useMemo(() => {
    if (!checkBillingAddress) {
      return true;
    }

    if (!billingAddressFetcher.data) {
      return undefined;
    }

    if (Object.keys(billingAddressFetcher.data).length === 0) {
      // empty billing address
      return false;
    }

    for (const field in billingAddressFetcher.data) {
      if (!checkValidity(billingAddressFetcher.data[field].value, billingAddressFetcher.data[field].validation)) {
        return false;
      }
    }

    return true;
  }, [ billingAddressFetcher.data, checkBillingAddress ]);

  const hasToShowBillingForm = useMemo(() => ((hasValidPaymentSource === false) || (hasValidBillingAddress === false)), [ hasValidPaymentSource, hasValidBillingAddress ]);

  const { planId: targetPlanId } = useParams();


  const formFetcher = useFetcher<string | IRouterActionError>();
  const actionData = formFetcher.data;
  const params = useParams();

  const navigation = useNavigation();
  const closeModal = useCloseModal({ blockNavigationIf: (navigation?.state === "submitting") || (formFetcher.state === "submitting"), goBackInHistoryOnClose: true });


  useEffect(() => {
    if (actionData === undefined) {
      // no actions yet, do nothing
      return;
    }

    if (actionData === "OK") {
      // all good, upgrade was successful
      // TODO get the target plan's name based on its id
      Toaster.success("Upgrade successful", `Your subscription will be upgraded to ${params.planId} in a few seconds.`);
      closeModal();
    } else {
      // error during upgrade
      // TODO actionData can hold useful information. we should display that to the user
      Toaster.error("Upgrade error", "There was an error upgrading your subscription.");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ actionData ]);


  return (
    <Modal headline="Upgrade Subscription" onClose={ closeModal }>
      <formFetcher.Form
        method="POST"
        action={ `/billing-data/subscription/upgrade/${targetPlanId}` }
        noValidate
        className={ cx("grid min-h-[490px] p-6 gap-10", hasToShowBillingForm && "lg:grid-cols-[350px,400px]") }
      >
        <div className="flex flex-col w-full gap-y-6">
          <PlanContent />
          { (user?.canUseAllCoupons || user?.canUseCoupons) && <CouponContent /> }

          { (((paymentSourcesFetcher.state === "loading") && (paymentSourcesFetcher.data === undefined)) || ((billingAddressFetcher.state === "loading") && !billingAddressFetcher.data)) && (
            <div className="flex h-full w-full items-end">
              <div className="flex flex-row items-center gap-x-2 text-sm text-gray dark:text-neutral-600">
                <Spinner className="w-4 h-4 animate-spin" />
                <div>Checking { (paymentSourcesFetcher.state === "loading") && <>payment method</> } { (paymentSourcesFetcher.state === "loading" && billingAddressFetcher.state === "loading") && <> and </>} { billingAddressFetcher.state === "loading" && <>billing address</> }...</div>
              </div>
            </div>
          ) }

          { ((!checkPaymentSources || (paymentSourcesFetcher.data !== undefined)) && (!checkBillingAddress || (billingAddressFetcher.data !== undefined)) && !hasToShowBillingForm) && (
            <div className="flex h-full w-full items-end">
              <ActionButtons />
            </div>
          )}

        </div>

        { hasToShowBillingForm && (
          <div className="w-full h-full border border-borderColor dark:border-neutral-200 rounded-lg p-6">
            <BillingInfoForm
              hasValidBillingAddress={ hasValidBillingAddress }
              billingAddress={ billingAddressFetcher.data }
              hasValidPaymentSource={ hasValidPaymentSource }
              paymentSources={ paymentSourcesFetcher.data }
              actionData={ actionData }
            />
          </div>
        )}
      </formFetcher.Form>
    </Modal>
  );
};
