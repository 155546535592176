import { useEffect } from "react";
import { Link, useActionData, useLocation, useNavigate } from "react-router-dom";

import AuthForm from "components/AuthForm";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";
import { LinkText } from "components/LinkText";
import Toaster from "components/Toaster";

import IRouterActionError from "routes/dataroutes/IRouterActionError";


function InvalidPasswordResetLinkPage() {
  return (
    <AuthPage
      title="Reset Password"
      heading="Reset Password"
    >
      <div>
        This password reset link is invalid. Please{" "}
        <Link to="/forgot-password"><LinkText>request a new link here</LinkText></Link>
        , or go back to the{" "}
        <Link to="/login"><LinkText>login page</LinkText></Link>.
      </div>
    </AuthPage>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const actionData = useActionData() as IRouterActionError;

  useEffect(() => {
    if (actionData && !actionData.error) {
      // password reset was successful
      Toaster.success("Your password has been changed successfully.", undefined, { toastId: "password-reset-success-toaster" });
      const timeoutHandle = setTimeout(() => {
        navigate("/login");
      }, 5000);

      return () => clearTimeout(timeoutHandle);
    }
  }, [ actionData, navigate ]);

  if (!token) {
    return <InvalidPasswordResetLinkPage />;
  }

  return (
    <AuthPage
      title="Reset Password"
      heading={[ "Reset password", "Enter your new password below to change it." ]}
    >

      <AuthForm
        hideEmailInput
        emailButtonText="Reset Password"
        askForPasswordConfirmation
        defaultErrorMessage="Something went wrong"
        errorToasterId="password-reset-error-toaster"
      >
        <input type="hidden" name="token" value={ token } />
      </AuthForm>

      <AuthPageLinks>
        <AuthPageLink text="" link={{ to: "/login", text: "Go Back" }} />
      </AuthPageLinks>

    </AuthPage>
  );
}
