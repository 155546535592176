import { ChangeEvent, useState } from "react";
import _ from "lodash";

import PasswordInput from "./PasswordInput";


interface IPasswordInputsProps {
  askForPasswordConfirmation?: boolean;
  autoFocus?: boolean;
}

export default function PasswordInputs(
  {
    askForPasswordConfirmation = false,
    autoFocus = false,
  }: IPasswordInputsProps
) {
  const [ password, setPassword ] = useState<string | undefined>();

  return (
    <>
      <PasswordInput
        value={ password }
        onChange={ (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value) }
        autoFocus={ autoFocus }
        name="password"
      />

      { askForPasswordConfirmation &&
        <PasswordInput
          label="Re-enter password"
          pattern={ _.escapeRegExp(password) }
          name="confirmPassword"
        />
      }
    </>
  );
};
