import { Switch } from "@headlessui/react";
import { useUserProvider } from "providers/UserProvider";
import { canUsePremium, canUseUltraPremium } from "utils/planUtils";
import type { AsyncApiParams, CollectorConfig } from "providers/HostedScrapingProvider/types";
import { Tooltip } from "components/Tooltip";
import { useSettingsFromApi } from "providers/ApiSettingsProvider";
import { ConfigProblem, ConfigProblemWithMessage } from "components/hostedScrapingValidators";
import { tooltips } from "./tooltips";
import { VALID_AMAZON_TLDS, VALID_EBAY_TLDS, VALID_GOOGLE_TLDS, VALID_REDFIN_TLDS, VALID_WALMART_TLDS } from "./validTLDs";
import { ProjectVariant } from "sdecontent";
import { disableFollowRedirectToFollowRedirect, followRedirectToDisableFollowRedirect } from "providers/HostedScrapingProvider/negateFollowRedirects";
import { cx } from "utils";
import { AdditionalOptionsTextBox } from "./AdditionalOptionsTextBox";
import { AdditionalOptionsListbox } from "./AdditionalOptionsListBox";
import { AdditionalOptionsNumberBox } from "./AdditionalOptionsNumberBox";
import { AdditionalOptionsMultiListbox, callbackWithCommaSeparatedString, splitCommaSeparatedString } from "./AdditionalOptionsMultiListBox";
import { useUser } from "routes/dataroutes/UserData";
import ParseResultsDropdown from "./ParseResultsDropdown";

const availableDeviceTypes = [
  { text: 'None', value: undefined },
  { text: 'Desktop', value: 'desktop' },
  { text: 'Mobile', value: 'mobile' }
];

const countryCodes = (availableCountryCodes: string[]) => [
  { text: 'None', value: undefined },
  ...(availableCountryCodes.map((code) => ({ text: code, value: code })))
];

const restrictedCountryCodes = [
  { text: 'None', value: undefined },
  { text: 'us', value: 'us' },
  { text: 'eu', value: 'eu' },
];

type AdditionalOptionsSwitchProps = {
  primaryText: string,
  secondaryText?: string,
  checked: boolean,
  callback: (state: boolean) => void,
  testId?: string
};

const AdditionalOptionsSwitch = ({ primaryText, secondaryText, checked, callback, testId }: AdditionalOptionsSwitchProps) => {
  return (<div className="mt-5 flex flex-row flex-wrap gap-2">
    <Switch
      data-testid={testId}
      checked={checked}
      onChange={callback}
      className={cx(checked ? "bg-blue-600" : "bg-gray-200 dark:bg-neutral-200",
        "relative inline-flex h-6 w-11 items-center rounded-full z-0")}
    >
      <span
        className={cx(checked ? "translate-x-6" : "translate-x-1",
          "inline-block h-4 w-4 transform rounded-full bg-white transition")}
      />
    </Switch>
    <div>
      <span className="text-normal">
        {primaryText}
      </span>
      {secondaryText && <span className="text-sm ml-2 text-lightGray dark:text-neutral-500">{secondaryText}</span>}
    </div>
  </div>);
}

const urlProjectTextsHS = {
  premium: "Activate premium residential and mobile IPs",
  ultraPremium: "Activate advanced bypass mechanism",
  redirect: "Disable follow redirects",
  rendering: "Activate javascript rendering",
  autoparse: "Activate to parse results",
  follow404: "Activate retrying 404 responses",
  sessionNumber: "Session number",
  binaryTarget: "Binary target",
  keepHeaders: "Use own headers",
}

const urlProjectTextsAP = {
  premium: "Premium residential and mobile IPs",
  ultraPremium: "Advanced bypass mechanism",
  redirect: "Disable follow redirects",
  rendering: "Javascript rendering",
  autoparse: "Parse results",
  follow404: "Retry 404 responses",
  sessionNumber: "Session number",
  binaryTarget: "Binary target",
  keepHeaders: "Use own headers",
}

const hashasProblem = (problems: ConfigProblemWithMessage[]) => (problem: ConfigProblem) => problems.some(p => p.problem === problem);

type UrlProjectAsyncApiParamsProps = {
  variant: ProjectVariant;
  collectorConfig: CollectorConfig;
  problems: ConfigProblemWithMessage[];
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined, apiParamsToChange?: AsyncApiParams) => AsyncApiParams;
};

export const UrlProjectAsyncApiParams = ({ variant, collectorConfig, problems, updateApiParams }: UrlProjectAsyncApiParamsProps) => {
  const user = useUser();
  const { subscription } = useUserProvider();
  const { availableCountryCodes } = useSettingsFromApi();
  // const config = collectorConfig as AsyncUrls;
  const countryCodesToShow = user?.geotargetingEnabled ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const texts = variant === 'hosted-scraper' ? urlProjectTextsHS : urlProjectTextsAP;

  const hasProblem = hashasProblem(problems);

  return (
    <>
      {canUsePremium(subscription?.plan_id) &&
        <Tooltip content={tooltips.activatePremiumResidentialAndMobileIps} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.premium} checked={Boolean(collectorConfig.apiParams?.premium)} callback={updateApiParams('premium')} testId="testPremiumSwitch" />
        </Tooltip>
      }

      {canUseUltraPremium(subscription?.plan_id) &&
        <Tooltip content={tooltips.activateAdvancedBypassMechanism} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.ultraPremium} checked={Boolean(collectorConfig.apiParams?.ultraPremium)} callback={updateApiParams('ultraPremium')} testId="testUltraPremiumSwitch" />
        </Tooltip>
      }

      <Tooltip content={tooltips.disableFollowRedirects} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.redirect}
          checked={Boolean(followRedirectToDisableFollowRedirect(collectorConfig.apiParams?.followRedirect as boolean | undefined))}
          callback={(newValue) => updateApiParams('followRedirect')(disableFollowRedirectToFollowRedirect(newValue))}
          testId="testRedirectsSwitch" />
      </Tooltip>

      {/* <AdditionalOptionsSwitch primaryText="Keep headers" checked={Boolean(project.config?.apiParams?.keepHeaders)} callback={updateApiParams('keepHeaders')}/> */}

      <Tooltip content={tooltips.activateJavascriptRendering} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.rendering} checked={Boolean(collectorConfig.apiParams?.render)} callback={updateApiParams('render')} testId="testRenderSwitch" />
      </Tooltip>

      {collectorConfig.apiParams?.render &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.waitForSelector}>Wait for selector</Tooltip>
          </div>
          <AdditionalOptionsTextBox value={collectorConfig.apiParams?.waitForSelector as string | undefined} placeholder="Please add selector" callback={updateApiParams('waitForSelector')} />
        </>
      }

      {
        (variant === 'hosted-scraper') &&
        (<Tooltip content={tooltips.autoparse} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.autoparse} checked={Boolean(collectorConfig.apiParams?.autoparse)} callback={updateApiParams('autoparse')} />
        </Tooltip>)
      }
      <Tooltip content={tooltips.activateRetrying404Responses} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.follow404} checked={Boolean(collectorConfig.apiParams?.retry404)} callback={updateApiParams('retry404')} />
      </Tooltip>
      {(variant === 'api-playground') && <ParseResultsDropdown collectorConfig={collectorConfig} updateApiParams={updateApiParams} />}

      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode" />
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500 dark:text-error-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.deviceType}>Device type</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig.apiParams?.deviceType as string | undefined} options={availableDeviceTypes} callback={updateApiParams('deviceType')} buttonTestId="testDeviceType" />
      <div className="mt-5">
        <Tooltip content={tooltips.maxCost}>Max Cost</Tooltip>
      </div>
      <AdditionalOptionsNumberBox value={collectorConfig.apiParams?.maxCost as number | undefined} placeholder="Please set a max cost value" callback={(newValue: string | undefined) => {
        if (newValue === undefined) {
          updateApiParams('maxCost')(undefined);
          return;
        }
        const parsed = Number.parseInt(newValue, 10);
        updateApiParams('maxCost')(parsed);

      }} />
      {hasProblem(ConfigProblem.InvalidMaxCost) && <div className="text-red-500 dark:text-error-500">Invalid max cost number</div>}


      {
        variant === 'api-playground' &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.sessionNumber}>Session number</Tooltip>
          </div>
          <AdditionalOptionsNumberBox value={collectorConfig.apiParams?.sessionNumber as number | undefined} placeholder="Please set a session number" callback={(newValue: string | undefined) => {
            if (newValue === undefined) {
              updateApiParams('sessionNumber')(undefined);
              return;
            }
            const parsed = Number.parseInt(newValue, 10);
            updateApiParams('sessionNumber')(parsed);
          }} />
          {hasProblem(ConfigProblem.InvalidSessionNumber) && <div className="text-red-500 dark:text-error-500">Invalid session number</div>}

          {/* { config.apiParams?.autoparse &&
        <>
          <div className="mt-5">
            Select format
          </div>
          <AdditionalOptionsListbox
            value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
            options={[
              { value: 'csv', text: 'CSV' },
              { value: 'json', text: 'JSON' }
            ]}
            callback={updateApiParams('outputFormat')}
          />
        </>
        } */}

          <Tooltip content={tooltips.binaryTarget} className="mt-5">
            <AdditionalOptionsSwitch primaryText={texts.binaryTarget} checked={Boolean(collectorConfig.apiParams?.binaryTarget)} callback={updateApiParams('binaryTarget')} />
          </Tooltip>

          <Tooltip content={tooltips.keepHeaders} className="mt-5">
            <AdditionalOptionsSwitch primaryText={texts.keepHeaders} checked={Boolean(collectorConfig.apiParams?.keepHeaders)} callback={updateApiParams('keepHeaders')} />
          </Tooltip>
        </>
      }
    </>
  );
}

type AmazonProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined) => AsyncApiParams,
  checkSubscription: boolean
}

export const AmazonProjectAsyncApiParams = ({ collectorConfig, problems, updateApiParams, checkSubscription }: AmazonProjectAsyncApiParamsProps) => {
  const user = useUser();
  const { availableCountryCodes } = useSettingsFromApi();
  const countryCodesToShow = (!checkSubscription || user?.geotargetingEnabled) ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    { text: 'None', value: undefined },
    ...VALID_AMAZON_TLDS.map(tld => ({ text: tld, value: tld }))
  ];

  return (
    <>
      <ParseResultsDropdown collectorConfig={collectorConfig} updateApiParams={updateApiParams} />
      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode" />
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500 dark:text-error-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList />
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500 dark:text-error-500">Invalid TLD</div>}

      {/* <div className="mt-5">
        Select format
      </div>
      <AdditionalOptionsListbox
        value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
        options={[
          { value: 'csv', text: 'CSV' },
          { value: 'json', text: 'JSON' }
        ]}
        callback={updateApiParams('outputFormat')}
        /> */}
    </>
  );
}

type GoogleProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: number | string | boolean | undefined) => AsyncApiParams,
  checkSubscription: boolean
}
export const GoogleProjectAsyncApiParams = ({ collectorConfig, problems, updateApiParams, checkSubscription }: GoogleProjectAsyncApiParamsProps) => {
  const user = useUser();
  const { availableCountryCodes } = useSettingsFromApi();
  const countryCodesToShow = (!checkSubscription || user?.geotargetingEnabled) ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    { text: 'None', value: undefined },
    ...VALID_GOOGLE_TLDS.map(tld => ({ text: tld, value: tld }))
  ];


  return (
    <>
      <ParseResultsDropdown collectorConfig={collectorConfig} updateApiParams={updateApiParams} />
      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode" />
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500 dark:text-error-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList />
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500 dark:text-error-500">Invalid TLD</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.UULE}>UULE</Tooltip>
      </div>
      <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.uule as string | undefined} placeholder="Please enter a UULE" callback={updateApiParams('uule')} />
      {hasProblem(ConfigProblem.InvalidUULE) && <div className="text-red-500 dark:text-error-500">Invalid UULE</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.numberOfResults}>Number of results</Tooltip>
      </div>
      <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.num as string | undefined} placeholder="Please set the number of results" callback={updateApiParams('num')} />
      {hasProblem(ConfigProblem.InvalidNum) && <div className="text-red-500 dark:text-error-500">Invalid number</div>}

      {/* <div className="mt-5">
        Select format
      </div>
      <AdditionalOptionsListbox
        value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
        options={[
          { value: 'csv', text: 'CSV' },
          { value: 'json', text: 'JSON' }
        ]}
        callback={updateApiParams('outputFormat')}
        /> */}

      {
        collectorConfig.type === 'async_google_search' &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.additionalParams}>Additional parameters</Tooltip>
          </div>
          <textarea className="w-full border-gray-200 dark:border-neutral-200 placeholder-gray-200 dark:placeholder-neutral-200 text-sm" value={collectorConfig?.apiParams?.additionalParams as string} placeholder='Enter additional parameters and separate with "&" (ampersand) sign.' onChange={(ev) => updateApiParams('additionalParams')(ev.target.value)} />
          {hasProblem(ConfigProblem.InvalidAdditionalParameters) && <div className="text-red-500 dark:text-error-500">Invalid parameters</div>}
        </>
      }
    </>
  );
}


type WalmartProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined) => AsyncApiParams
}

export const WalmartProjectAsyncApiParams = ({ collectorConfig, problems, updateApiParams }: WalmartProjectAsyncApiParamsProps) => {

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    { text: 'None', value: undefined },
    ...VALID_WALMART_TLDS.map(tld => ({ text: tld, value: tld }))
  ];

  const validSortByOptions = [
    { text: 'None', value: undefined },
    { text: 'Relevancy', value: 'relevancy' },
    { text: 'Helpfulness', value: 'helpful' },
    { text: 'Submission - descending', value: 'submission-desc' },
    { text: 'Submission - ascending', value: 'submission-asc' },
    { text: 'Rating - descending', value: 'rating-desc' },
    { text: 'Rating - ascending', value: 'rating-asc' },
  ];

  return (
    <>
      <ParseResultsDropdown collectorConfig={collectorConfig} updateApiParams={updateApiParams} />
      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList />
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500 dark:text-error-500">Invalid TLD</div>}

      {
        (collectorConfig.type === 'async_walmart_search' || collectorConfig.type === 'async_walmart_category' || collectorConfig.type === 'async_walmart_review') &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.walmartPage}>Page</Tooltip>
          </div>
          <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.page as string | undefined} placeholder="Please enter page number" callback={updateApiParams('page')} />
          {hasProblem(ConfigProblem.InvalidNum) && <div className="text-red-500 dark:text-error-500">Invalid page number</div>}
        </>
      }
      {
        (collectorConfig.type === 'async_walmart_review') &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.walmartSortBy}>Sort by</Tooltip>
          </div>
          <AdditionalOptionsListbox value={collectorConfig?.apiParams?.sort as string | undefined} options={validSortByOptions} callback={updateApiParams('sort')} shortList />
          {hasProblem(ConfigProblem.InvalidSortBy) && <div className="text-red-500 dark:text-error-500">Invalid sort by</div>}
        </>
      }
    </>
  );
}
type EbayProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined) => AsyncApiParams
}

export const EbayProjectAsyncApiParams = ({ collectorConfig, problems, updateApiParams }: EbayProjectAsyncApiParamsProps) => {
  const user = useUser();
  const { availableCountryCodes } = useSettingsFromApi();
  const countryCodesToShow = user?.geotargetingEnabled ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const hasProblem = hashasProblem(problems);


  const domainsToShow = [
    { text: 'None', value: undefined },
    ...VALID_EBAY_TLDS.map(tld => ({ text: tld, value: tld }))
  ];


  const conditionsToShow = [
    { text: 'None', value: undefined },
    { text: 'New', value: 'new' },
    { text: 'Used', value: 'used' },
    { text: 'Open box', value: 'open_box' },
    { text: 'Refurbished', value: 'refurbished' },
    { text: 'For parts', value: 'for_parts' },
    { text: 'Not working', value: 'not_working' }
  ];

  const validSortByOptions = [
    { text: 'None', value: undefined },
    { text: 'Ending soonest', value: 'ending_soonest' },
    { text: 'Newly listed', value: 'newly_listed' },
    { text: 'Price lowest', value: 'price_lowest' },
    { text: 'Price highest', value: 'price_highest' },
    { text: 'Distance nearest', value: 'distance_nearest' },
    { text: 'Best match', value: 'best_match' }
  ];

  const validShowOnlyOptions = [
    { text: 'Returns accepted', value: 'returns_accepted' },
    { text: 'Authorized seller', value: 'authorized_seller' },
    { text: 'Completed items', value: 'completed_items' },
    { text: 'Sold items', value: 'sold_items' },
    { text: 'Sale items', value: 'sale_items' },
    // { text:'Deals and savings', value: 'deals_and_savings' },
    { text: 'Listed as lots', value: 'listed_as_lots' },
    { text: 'Search in description', value: 'search_in_description' },
    { text: 'Benefits charity', value: 'benefits_charity' },
    { text: 'Authenticity guarantee', value: 'authenticity_guarantee' },
    // { text:'PSA vault', value: 'psa_vault' }
  ];

  const validBuyingFormatOptions = [
    { text: 'None', value: undefined },
    { text: 'Buy it now', value: 'buy_it_now' },
    { text: 'Auction', value: 'auction' },
    { text: 'Accepts offers', value: 'accepts_offers' },
  ];


  return (
    <>
      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList />
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500">Invalid TLD</div>}
      <ParseResultsDropdown collectorConfig={collectorConfig} updateApiParams={updateApiParams} />

      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode" />
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500">Invalid country code</div>}

      {(collectorConfig.type === 'async_ebay_search' &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.ebaySearchPage}>Page</Tooltip>
          </div>
          <AdditionalOptionsNumberBox value={collectorConfig?.apiParams?.page as string | undefined} callback={updateApiParams('page')} placeholder="1" />
          {hasProblem(ConfigProblem.InvalidNum) && <div className="text-red-500">Invalid page number</div>}

          <div className="mt-5">
            <Tooltip content={tooltips.ebaySearchItemsPerPage}>Items per page</Tooltip>
          </div>
          <AdditionalOptionsNumberBox value={collectorConfig?.apiParams?.items_per_page as string | undefined} callback={updateApiParams('items_per_page')} placeholder="20" />
          {hasProblem(ConfigProblem.InvalidItemsPerPage) && <div className="text-red-500">Invalid items per page value</div>}

          <div className="mt-5">
            <Tooltip content={tooltips.ebaySearchSellerId}>Seller id</Tooltip>
          </div>
          <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.seller_id as string | undefined} callback={updateApiParams('seller_id')} placeholder="seller_name" />
          {/* {hasProblem(ConfigProblem.InvalidSellerId) && <div className="text-red-500">Invalid seller id</div>} */}

          <div className="mt-5">
            <Tooltip content={tooltips.ebaySearchCondition}>Condition</Tooltip>
          </div>
          <AdditionalOptionsMultiListbox values={splitCommaSeparatedString(collectorConfig?.apiParams?.condition as string | undefined)} options={conditionsToShow} callback={callbackWithCommaSeparatedString(updateApiParams('condition'))} shortList />
          {/* {hasProblem(ConfigProblem.InvalidCondition) && <div className="text-red-500">Invalid condition</div>} */}

          <div className="mt-5">
            <Tooltip content={tooltips.ebayShowOnly}>Show only</Tooltip>
          </div>
          <AdditionalOptionsMultiListbox values={splitCommaSeparatedString(collectorConfig?.apiParams?.show_only as string | undefined)} options={validShowOnlyOptions} callback={callbackWithCommaSeparatedString(updateApiParams('show_only'))} shortList />
          {/* {hasProblem(ConfigProblem.InvalidCondition) && <div className="text-red-500">Invalid condition</div>} */}

          <div className="mt-5">
            <Tooltip content={tooltips.ebayBuyingFormat}>Buying format</Tooltip>
          </div>
          <AdditionalOptionsListbox value={collectorConfig?.apiParams?.buying_format as string | undefined} options={validBuyingFormatOptions} callback={updateApiParams('buying_format')} shortList />
          {/* {hasProblem(ConfigProblem.InvalidCondition) && <div className="text-red-500">Invalid condition</div>} */}

          <div className="mt-5">
            <Tooltip content={tooltips.ebaySearchSortBy}>Sort by</Tooltip>
          </div>
          <AdditionalOptionsListbox value={collectorConfig?.apiParams?.sort_by as string | undefined} options={validSortByOptions} callback={updateApiParams('sort_by')} shortList />
          {hasProblem(ConfigProblem.InvalidSortBy) && <div className="text-red-500 dark:text-error-500">Invalid sort by</div>}
        </>
      )}
    </>
  );
}

type RedfinProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined, apiParamsToChange?: AsyncApiParams) => AsyncApiParams,
  checkSubscription: boolean
}

export const RedfinProjectAsyncApiParams = ({ collectorConfig, problems, updateApiParams, checkSubscription }: RedfinProjectAsyncApiParamsProps) => {
  const user = useUser();
  const { availableCountryCodes } = useSettingsFromApi();
  const countryCodesToShow = (!checkSubscription || user?.geotargetingEnabled) ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    { text: 'None', value: undefined },
    ...VALID_REDFIN_TLDS.map(tld => ({ text: tld, value: tld }))
  ];

  return (
    <>
      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode" />
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500 dark:text-error-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain} >Domain</Tooltip>
      </div>

      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList />
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500 dark:text-error-500">Invalid TLD</div>}

      <Tooltip content={tooltips.rawContent} className="mt-5">
        <AdditionalOptionsSwitch primaryText="Raw JSON by Redfin" secondaryText="Please note that the format of this JSON is not guaranteed." checked={Boolean(collectorConfig.apiParams?.raw)} callback={updateApiParams('raw')} testId="testRawSwitch" />
      </Tooltip>
      <ParseResultsDropdown collectorConfig={collectorConfig} updateApiParams={updateApiParams} />
      {/* <div className="mt-5">
        Select format
      </div>
      <AdditionalOptionsListbox
        value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
        options={[
          { value: 'csv', text: 'CSV' },
          { value: 'json', text: 'JSON' }
        ]}
        callback={updateApiParams('outputFormat')}
        /> */}
    </>
  );
}
