import { isValidRedfinUrl } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const RedfinSearch: SDEContent = {
  projectType: 'async_redfin_search',
  projectCategory: 'redfin',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/redfin/search`;
  },
  docsSection: 'redfin-property-search-api',
  docsSectionAsync: 'redfin-property-search-api-async',
  inputKey: 'url',
  inputsKey: 'urls',
  validatorFunction: isValidRedfinUrl,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'URL',
      listLiteralInputPlaceholder: 'Enter one URL per line',
    },
    inputSectionLabels: {
      inputSectionTitle: "URL",
      inputSectionDescription: "Enter URLs in the input field or upload CSV with your URLs to begin scraping Redfin.",
    },
    projectContent: {
      title: 'Redfin property - search', 
      description: 'Extract details of property listings on Redfin. Download the results in a well-structured JSON file.',
      icon: 'redfin',
      inputTitle: 'URL',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Area search URL',
      inputSectionDescription: 'The URL of the redin property listings page.',
    },
    projectContent: {
      title: 'Redfin property pages - search property listings',
      description: 'Extract property listings on Redfin.',
      icon: 'redfin',
    },
    enterInputsPlaceholder: 'https://www.redfin.com/city/1826/MA/Boston',
    enterInputsMessage: 'Enter the redfin property search page url',
    codeViewPlaceHolder: 'Please provide a valid search url',
    dropdownTitle: 'Redfin - search for property listings'
  }
};
