export const tooltips = {
  list: "Insert one or more URLs, ASINs or keywords and separate each one by pressing 'Enter' or 'Return'.",
  uploadFile: "Upload a CSV file with URLs, ASINs or keywords. Drop the file into the field or click on the 'browse' button. The file can have max. 10.000 entries, should not include a header row or any commas. Each URL, ASIN or keyword should be in a separate row.",
  webhookInput: "Provide a webhook URL where we can poll the entries for your project.",
  countryCode: "Activate country geo-targeting to use country specific proxies, for example US proxies. This parameter does not increase the cost.",
  deviceType: "Default is device_type=desktop. The device type will be overridden if you use keep_headers=true and send your own user agent in the request header.",
  topLevelDomain: "Activate to choose the TLD for your URLs.",
  UULE: <>Set a region for your search. You can find an online UULE generator <a href="https://site-analyzer.pro/services-seo/uule/" target="_blank" rel="noreferrer">here</a>.</>,
  numberOfResults: "Choose how many results per keyword should be included in the results. The max. is 100",
  additionalParams: <>You can find the list of other available parameters in the <a href='https://www.scraperapi.com/documentation/curl/#Structured-Data-Collection-Method' target='_blank' rel="noreferrer">documentation</a>.</>,
  activatePremiumResidentialAndMobileIps: "Activate premium proxies. A request costs 10 API credits and 25 in combination with javascript rendering.",
  activateAdvancedBypassMechanism: "Activate advanced bypass for very difficult domains, such as Linkedin. A request costs 30 API credits.",
  disableFollowRedirects: "Disable follow redirects.",
  activateJavascriptRendering: "Activate if the targeted URLs require rendering javascript. We will fetch these pages using a headless browser.",
  waitForSelector: "You can tell to wait for a DOM element (selector) to appear on the page when rendering.",
  activateRetrying404Responses: "Activate to retry URLs if they responded with 404 error.",
  sessionNumber: <>Reuse the same proxy by setting <pre>session_number=123</pre> for example.</>,
  binaryTarget: "Helpful when trying to scrape files or images. This tells our API that the target is a file.",
  keepHeaders: <>Use your own custom headers by setting <pre>keep_headers=true</pre> along with sending your own headers to the API.</>,
  webhookURL: "Add your custom webhook URL. Make sure the webhook is publicly reachable.",
  scheduleTime: "Choose when to schedule your first job. From this time on, the jobs will run automatically based on your selected scraping interval.",
  scrapingInterval: "Choose how and when your jobs should be repeated.",
  autoparse: "Activate to receive your results as JSON or CSV for certain domains instead of plain HTML. The domains are google, amazon and walmart.",
  rawEncoding: "The result will be sent as in the body of a POST request. If unsure, select this.",
  multipartFormDataEncoding: "The result will be sent as a multipart/form-data POST request.",
  walmartPage: "Choose the page number you need results from.",
  walmartSortBy: "Sort your results by relevance, helpfulness submission type or rating.",

  ebaySearchSortBy: "Sort your results by relevance, helpfulness, submission time or rating.",
  ebaySearchPage: "Choose the page number you need results from.",
  ebaySearchItemsPerPage: "Choose how many items per page you need.",
  ebaySearchSellerId: "Choose the seller ID you need results from.",
  ebaySearchCondition: "Choose the condition of the items you need results from.",
  ebayShowOnly: "List only certain types of items in the search.",
  ebayBuyingFormat: "Select the mode of purchase you need results from: auction, buy it now or accept offers.",


  // Used for redfin, but the content is generic
  rawContent: "Extract the raw JSON provided by Redfin and do not additionally parse it by ScraperAPI. Therefore we can not provide support if the structure changes.",

  // TODO maybe this is not the perfect place for this next tooltip
  runNowDisabled: "It is not possible to run a new job, if there is still a job running. You can see running jobs in the job list with the status \"started\". Please wait until all jobs are done to run a new one.",
  /// ProjectDetails
  projectsStatus: "The status is based on if you enabled or disabled the scheduling of the project.",
  projectsCreated: "The date when you created that project.",
  projectsCostPerJob: "As soon as your first job run, we will show the price per job.",
  projectsCreditsSpent: "The credits you spent already in that project.",
  projectsCreditsSpentInBillingPeriod: "The credits you spent for this project in the current billing cycle.",
  projectsSuccessRate: "The success rate is based on how successful the responses of the jobs are. If there are a lot of failed responses, the success rate will decrease.",
  projectsScheduled: "The date and time when your next job will start.",
  maxCost: "A limit on the maximum API credits you'd like to spend per each individual scrape"
} as const;
