import _ from "lodash";

import { BillingPeriodUnit, Plan } from "providers/UserProvider";
import { Coupon, CouponError } from "types/Coupons";


export const canUsePremium = (planSlug: string | undefined) => {
  return planSlug !== "free" && planSlug !== undefined;
}

export const canUseUltraPremium = (planSlug: string | undefined) => {
  return planSlug !== "free" && planSlug !== undefined;
}

export const isEnterprise = (planSlug: string | undefined) => {
  return planSlug?.startsWith("enterprise");
};

export const manualRenewToThisIfScheduled = (planSlug: string | undefined) => {
  return planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug));
};

export const isUsingChargebeeCustomFields = (planSlug: string | undefined) => {
  return planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug));
};

export const hasToContactUsBeforeChange = (planSlug: string | undefined) => {
  return planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug));
};

export const isAnnualPlan = (planSlug: string | undefined) => {
  return planSlug?.startsWith("annual_");
};

export const getPriceMultiplier = (period1: number, periodUnit1: BillingPeriodUnit, period2: number, periodUnit2: BillingPeriodUnit) => {
  const periodMultipliers = {
    month: 1,
    year: 12
  };

  return (periodMultipliers[periodUnit2] * period2) / (periodMultipliers[periodUnit1] * period1);
};

export const applyCoupon = (plan: Plan, coupon?: Coupon | CouponError) => {
  if (!coupon || _.has(coupon, "error")) {
    plan.discount_price = undefined;
  } else {
    const couponType = coupon as Coupon;
    if (couponType.discount_type === "percentage") {
      plan.discount_price = ((plan.price || 0) * (100 - (couponType.discount_percentage || 0))) / 100;
    } else if (couponType.discount_type === "fixed_amount") {
      plan.discount_price = Math.max((plan.price || 0) - (couponType.discount_amount || 0), 0);
      // TODO apply fix amount discount
    } else {
      // unsupported discount type, or empty coupon code
      plan.discount_price = undefined;
    }
  }

  return plan;
};
