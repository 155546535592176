import { Tooltip } from "components/Tooltip";
import { tooltips } from "./tooltips";
import { AdditionalOptionsListbox } from "./AdditionalOptionsListBox";
import type { AsyncApiParams, CollectorConfig } from "providers/HostedScrapingProvider/types";

type ParseResultsDropdownProps = {
  collectorConfig: CollectorConfig;
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined, apiParamsToChange?: AsyncApiParams) => AsyncApiParams;
};
const ParseResultsDropdown = ({ collectorConfig, updateApiParams }: ParseResultsDropdownProps) => {
  return <>
    <div className="mt-5">
      <Tooltip content={tooltips.autoparse}>Parse results</Tooltip>
    </div>
    <AdditionalOptionsListbox value={
      collectorConfig.apiParams?.autoparse === true && (
        collectorConfig.apiParams?.outputFormat === 'json'
        || collectorConfig.apiParams?.outputFormat === undefined
      ) ? 'json'
        : collectorConfig.apiParams?.autoparse === true && collectorConfig.apiParams?.outputFormat === 'csv' ? 'csv'
          : 'do_not_parse'
    } options={
      [
        { text: 'Do not parse results', value: 'do_not_parse' },
        { text: 'Parse to JSON (Only available for certain domains)', value: 'json' },
        { text: 'Parse to CSV (Only available for certain domains)', value: 'csv' }
      ]
    } callback={(new_value) => {
      switch (new_value) {
        case 'do_not_parse': {
            const updatedApiParams = updateApiParams('outputFormat')(undefined);
            updateApiParams('autoparse')(false, updatedApiParams);
            break;
          }
        case 'json': {
            const updatedApiParams = updateApiParams('outputFormat')('json');
            updateApiParams('autoparse')(true, updatedApiParams);
            break;
          }
        case 'csv': {
            const updatedApiParams = updateApiParams('outputFormat')('csv');
            updateApiParams('autoparse')(true, updatedApiParams);
            break;
          }
      };
    }} shortList buttonTestId="testApiPlaygroundOutputFormat" />
  </>
};
export default ParseResultsDropdown;

