import axios, { AxiosRequestConfig } from "axios";
import { NewProjectConfig, ProjectConfig } from "providers/HostedScrapingProvider";
import { CostCalculationProject } from "providers/HostedScrapingProvider/types";
import { ApiCallConfig } from "routes/dashboard/ApiPlaygroundTypes";
import { CaptchaTokenType } from "types/Captcha";


// This is the scraperapi-server's address, not the scraperapi-api's
export const API_HOST = process.env.REACT_APP_API_URL;


export interface ApiErrorResponse {
  message: string | undefined;
  error_code: string; // TODO this is kind of an enumeration in the backend services, maybe we should list the possible values here?
  details?: string | object;
}


//@ts-ignore
export interface ChargebeeApiErrorResponse extends ApiErrorResponse {
  chargebee_api_error_code: string;
  chargebee_error_code: string;
}

export type JobOrder = undefined | 'job_completed_first' | 'job_failed_first' | 'last_run_first' | 'last_run_last';

export class ApiError<T extends ApiErrorResponse> extends Error implements ApiErrorResponse {

  constructor(errorResponse: T) {
    super(errorResponse.message);

    this.error_code = errorResponse.error_code;
    this.details = errorResponse.details;
  }

  static from(error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data) {
        const jsonResponse = ((responseData) => {
          if (typeof responseData === 'object') {
            return responseData;
          }
          if (typeof responseData === 'string') {
            try {
              return JSON.parse(responseData);
            } catch (err) {
              return responseData;
            }
          }
        })(error.response.data);

        if (typeof jsonResponse === 'object') {
          if (('message' in jsonResponse) && ('error_code' in jsonResponse)) {
            return new ApiError(jsonResponse);
          }

          return new ApiError({
            message: jsonResponse.message,
            error_code: 'unknown',
            details: jsonResponse
          });
        }

        if (typeof jsonResponse === 'string') {
          return new ApiError({
            message: jsonResponse,
            error_code: 'unknown'
          });
        }
      }
    }

    return undefined;
  }

  error_code: string;
  details?: any;

}

async function scraperFetch(path: string, opts?: AxiosRequestConfig) {
  if (path[0] !== "/") throw Error(`Wrongly formatted path: ${path}`);

  try {
    const res = await axios(`${API_HOST}${path}`, {
      withCredentials: true,
      ...opts
    });

    return res.data;
  } catch (error) {
    const apiError = ApiError.from(error);

    throw apiError || error;
  }
}

const scraperApi = {
  auth: {
    me: async (opts?: AxiosRequestConfig) => scraperFetch("/users", opts),
    github: {
      state: async () => scraperFetch("/oauth/github/state")
    },
    logs: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/users/logs", { ...opts }),
    changePassword: async (oldPassword: string, newPassword: string) =>
      scraperFetch(`/users/password`, {
        method: "POST",
        data: {
          password: oldPassword,
          newPassword: newPassword
        }
      }),
    google: {
      signin: async (token: string) =>
        scraperFetch("/users/sign-in/google", {
          method: "POST",
          data: { token }
        }),
      signup: async (token: string) =>
        scraperFetch("/users/sign-up/google", {
          method: "POST",
          data: { token }
        })
    },
    coupons: async (couponCode: string, opts?: AxiosRequestConfig) =>
      scraperFetch(`/users/coupons`, {
        ...opts,
        method: "POST",
        data: {
          coupons: [ couponCode ],
          action: "apply"
        }
      }),
    sendActivationEmail: async (email: string) =>
      scraperFetch("/users/sendActivationEmail", {
        method: "POST",
        data: { email }
      }),

    activateAccount: async (token: string, opts?: AxiosRequestConfig) =>
      scraperFetch("/users/activate", { ...opts, params: { ...opts?.params, token } }),

    resetPassword: async (password: string, token: string) =>
      scraperFetch("/users/reset", { method: "POST", data: { password }, params: { token } }),

    forgotPassword: (email: string) =>
      scraperFetch("/users/request-reset", {
        method: "POST",
        data: { email }
      }),
    logout: async () => scraperFetch("/users/logout", { method: "POST" }),
    login: async (
      formData: {
        email: string,
        password: string,
        token: string,
        tokenType: CaptchaTokenType,
        firstPage?: string | { url: string; visited: Date },
        referer?: string
      }) =>
      scraperFetch("/users/sign-in/email", {
        method: "POST",
        data: formData
      }),
    signup: async (
      formData: {
        email: string,
        password: string,
        token: string,
        tokenType: CaptchaTokenType,
        firstPage?: string | { url: string; visited: Date } | undefined,
        referer?: string
      }
    ) =>
      scraperFetch("/users/sign-up/email", {
        method: "POST",
        data: formData
      }),
    requestDeletion: async () => scraperFetch("/users/request-deletion", { method: "POST" }),
  },
  apiKeys: {
    fetchApiKeys: async (opts?: AxiosRequestConfig) => scraperFetch("/users/apiKeys", opts),
    newApiKey: async (opts?: AxiosRequestConfig) => scraperFetch("/users/apiKey", { ...opts, method: "PUT" }),
    removeApiKey: async (apiKey: string, opts?: AxiosRequestConfig) => scraperFetch(`/users/apiKeys/${apiKey}`, { ...opts, method: "DELETE" })
  },
  status: {
    issues: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/status/issues/active", opts),
    allIssues: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/issues/active"),
    changelog: async (limit: number, opts?: AxiosRequestConfig) =>
      scraperFetch("/changelog?" + new URLSearchParams({ limit: limit.toString(10) }).toString()),
  },
  billing: {
    getBillingAddress: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/billing-address", opts),
    setBillingAddress: async (billingAddress: any /* TODO set proper type here */, opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/billing-address", { ...opts, method: "POST", data: billingAddress }),
    getPaymentSources: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/payment-sources", opts),
    setPaymentSource: async (paymentSource: any /* TODO set proper type here */, opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/payment-source", { ...opts, method: "POST", data: paymentSource }),
    payPal: {
      createBillingAgreementToken: async (opts?: AxiosRequestConfig) =>
        scraperFetch("/billing/paypal/billing-agreement/create-token"),
    },
    invoices: {
      getUnpaidInvoicesCount: async (opts?: AxiosRequestConfig) =>
        scraperFetch("/users/invoices/not-paid/count"),
    }
  },
  subscription: {
    update: async (
      planId: string,
      immediate: boolean,
      coupons?: string[],
      keepScheduledChanges?: boolean,
      opts?: AxiosRequestConfig
    ) =>
      scraperFetch("/subscriptions", {
        ...opts,
        method: "POST",
        data: {
          plan: planId,
          immediate,
          coupons,
          keepScheduledChanges
        }
      }),
    autoRenew: async (ceilingPercentage: string, opts?: AxiosRequestConfig) =>
      scraperFetch(`/users/auto-renewal/${ceilingPercentage}`, {
        ...opts,
        method: "PUT"
      }),
    clearAutoRenew: async (opts?: AxiosRequestConfig) =>
      scraperFetch(`/users/auto-renewal/clear`, { ...opts, method: "PUT" }),
    renew: async (
      subscriptionId: string,
      coupons?: string[],
      keepScheduledChanges?: boolean,
      opts?: AxiosRequestConfig
    ) =>
      scraperFetch(`/subscriptions/${subscriptionId}/renewalDate`, {
        ...opts,
        method: "POST",
        data: {
          keepScheduledChanges,
          coupons
        }
      }),
    billingAddress: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/billing-address", { ...opts }),
    paymentSources: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/payment-sources", opts),
    paymentMethod: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/payment-method", { ...opts }),
    addPaymentMethod: async(addPaymentMethodRequest: any, opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/payment-method", { ...opts, method: "POST", data: addPaymentMethodRequest }),
    billingSessions: async () => scraperFetch("/subscriptions/billing-session"),
    payNowLink: async () => scraperFetch("/subscriptions/pay-now-link"),

    cancel: async () =>
      scraperFetch(`/subscriptions`, {
        method: "DELETE"
      }),
    removeScheduledChanges: async () =>
      scraperFetch(`/subscriptions/scheduled-changes`, {
        method: "DELETE"
      }),
    details: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/subscriptions/details", opts),
    checkCoupons: async (couponCodes: string[], targetPlanSlug?: string, opts?: AxiosRequestConfig) =>
      scraperFetch("/users/coupons", {
        ...opts,
        method: "POST",
        data: {
          coupons: couponCodes,
          targetPlanSlug,
          action: "check"
        }
      }),
  },
  stats: {
    perDomainReport: async (query?: { source: "clickhouse" | "redis" }) =>
      scraperFetch("/users/report?" + new URLSearchParams(query)),
    usageHistory: async (filters: URLSearchParams, opts?: AxiosRequestConfig) =>
      scraperFetch("/users/usage-history", { ...opts, params: filters }),
  },
  hostedScraping: {
    projects: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/hostedscraping/projects", opts),
    singleProject: async (projectId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${encodeURIComponent(projectId)}`, opts),
    jobs: async (projcetId: number, limit: number = 10, orderBy: JobOrder, opts?: AxiosRequestConfig) => {
      const queryParams = new URLSearchParams();
      queryParams.append('limit', limit.toString());
      if (orderBy !== undefined) {
        queryParams.append('orderBy', orderBy);
      }
      return scraperFetch(`/hostedscraping/project/${projcetId}/jobs?${queryParams.toString()}`, opts);
    },
    saveProject: async (project: NewProjectConfig, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/`, {
        ...opts,
        method: "POST",
        data: project,
      }),
    updateProject: async (project: ProjectConfig, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${project.id}`, {
        ...opts,
        method: "PATCH",
        data: project,
      }),
    deleteProject: async (projectId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}`, {
        ...opts,
        method: "DELETE",
      }),
    scheduleNowProject: async (projectId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}/schedulenow`, {
        ...opts,
        method: "POST",
      }),
    resendJobResult: async (projectId: number, jobId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}/job/${jobId}/resendresult`, {
        ...opts,
        method: "POST",
      }),
    downloadProjectInput: async(inputKey: string, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/input?inputKey=${encodeURIComponent(inputKey)}`, {
        ...opts,
        // Prevent JSON decode:
        transformResponse: (response) => (response),
        method: "GET",
      }),
    uploadProjectInput: async(body: unknown, projectType: string, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/input`, {
        ...opts,
        method: "POST",
        data: body,
        params: { projecttype: projectType },
        headers: {
          'content-type': 'application/octet-stream',
        }
      }),
    getJobJSONResult: async(projectId: number, jobId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}/job/${jobId}/result`, {
        ...opts,
        // Prevent JSON decode:
        transformResponse: (response) => (response),
        method: "GET",
      }),
    getJobBinaryResult: async(projectId: number, jobId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}/job/${jobId}/result`, {
        ...opts,
        method: "GET",
        responseType: 'arraybuffer',
      }),
    getJobJSONErrorReport: async(projectId: number, jobId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}/job/${jobId}/errorreport`, {
        ...opts,
        // Prevent JSON decode:
        transformResponse: (response) => (response),
        method: "GET",
      }),
    tryIt: async (project: ApiCallConfig, opts?: AxiosRequestConfig) =>
      scraperFetch("/hostedscraping/tryit", {
        ...opts,
        method: "POST",
        data: project
      }),
    projectCost: async (project: CostCalculationProject, sourceProduct: 'api' | 'api_playground' | 'async' | 'hosted', opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/cost?source_product=${sourceProduct}`, {
        ...opts,
        method: "POST",
        data: project,
      }),
    errorReport: async (publicJobId: string, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/job/${publicJobId}/errorreport`, {
        ...opts,
        method: "GET",
      }),
    cancelJob: async (projectId: number, jobId: number, opts?: AxiosRequestConfig) =>
      scraperFetch(`/hostedscraping/project/${projectId}/job/${jobId}`, {
        ...opts,
        method: "DELETE",
      }),
  },
  settings: {
    availableCountryCodes: async (opts?: AxiosRequestConfig) =>
      scraperFetch("/geo", opts),
  },
  onboarding: {
    saveAnswers: async (formDataJson: any) => {
      return scraperFetch("/users/onboarding", {
        method: "POST",
        data: formDataJson
      });
    }
  },
  cancellationSurvey: {
    saveAnswers: async (formDataJson: any) => {
      return scraperFetch("/users/cancellation-survey", {
        method: "POST",
        data: formDataJson,
      });
    }
  }
};

export default scraperApi;
